import ReactCookies from 'react-cookies';
import axios, {
  logToSplunk,
} from '../components/_generic/axiosSplunk/axiosSplunk';
import COOKIES from '../config/cookies/cookies';
import {
  URLBASKET,
  BRAND_HYPHEN_DOMAIN,
  URLGETBASKET,
  URLVIPUPDATE,
} from '../config/setup/setup';
import { setBasketParameters } from '../helpers/basket';
import ErrorTrackingService from '../helpers/errorTrackingService';
import errorTrackingService from '../helpers/errorTrackingService';
import httpCommonHeaders from '../helpers/httpCommonHeaders';
import { createBasket } from '../redux/actions/basket';

/**
 * Helper to fetch a basket from URL
 *
 * @param {string} url  basket url
 */
export const fetchBasketWithURL = async (url) => {
  const separator = url.includes('?') ? '&' : '?';
  try {
    const response = await axios(`${url}${separator}timestamp=${Date.now()}`, {
      headers: {
        vipVersion: 'v1.1',
        ...httpCommonHeaders({ isGPK: true }),
      },
      method: 'GET',
      withCredentials: true,
    });
    const data =
      response.data && Array.isArray(response.data)
        ? response.data[0]
        : response.data;

    if (
      data &&
      data.id &&
      data.id.length > 10 &&
      !ReactCookies.load(COOKIES.basketToken)
    ) {
      ReactCookies.save(COOKIES.basketToken, data.id, {
        domain: `.${process.env.NEXT_PUBLIC_DOMAIN_NAME}`,
        maxAge: 60 * 60 * 24 * 7,
        path: '/',
        secure: true,
      });
    }

    return data;
  } catch (error) {
    console.error(error);
    ErrorTrackingService.logError(error);

    return {};
  }
};

export const fetchBasketWithURLWithCT = async (url) => {
  const separator = url.includes('?') ? '&' : '?';
  try {
    const response = await axios(`${url}${separator}timestamp=${Date.now()}`, {
      headers: httpCommonHeaders({ isGPK: true }),
      method: 'GET',
      withCredentials: true,
    });
    const data =
      response.data && Array.isArray(response.data)
        ? response.data[0]
        : response.data;

    const isValidBasketToken =
      ReactCookies.load(COOKIES.basketToken) === data.id;
    if (data && data.id && data.id.length > 10 && !isValidBasketToken) {
      logToSplunk({
        bt: data?.id,
        btOrphan: ReactCookies.load(COOKIES.basketToken),
        ct: data?.customerToken,
        description: `isValidBasketToken ->${isValidBasketToken}`,
      });
      ReactCookies.remove(COOKIES.basketToken, {
        domain: `.${process.env.NEXT_PUBLIC_DOMAIN_NAME}`,
        path: '/',
      });
      ReactCookies.save(COOKIES.basketToken, data.id, {
        domain: `.${process.env.NEXT_PUBLIC_DOMAIN_NAME}`,
        maxAge: 60 * 60 * 24 * 7,
        path: '/',
        secure: true,
      });
    }

    return data;
  } catch (error) {
    console.error(error);
    ErrorTrackingService.logError(error);

    return {};
  }
};
/**
 * Helper to replace a basket to help update existing baskets
 *
 * @param {string} basketToken         basket token to update
 * @param {object} data                new basket, an array of products
 * @param {boolean} [isAuthenticated]  is user logged in
 * @param {boolean} [isEligibleForVip] user is eligible for vip
 */
export const replaceBasket = async (
  basketToken,
  data,
  isAuthenticated,
  isEligibleForVip,
) => {
  if (!basketToken || !data) {
    const error = {
      message: 'basketToken or data are malformed',
      name: 'replaceBasket',
    };
    ErrorTrackingService.logError(error);

    return null;
  }

  const url = `${URLBASKET}/${basketToken}`;
  let customerToken = null;
  if (isAuthenticated) {
    customerToken = ReactCookies.load(COOKIES.customerToken);
  }
  try {
    await axios(url, {
      data: {
        brand: BRAND_HYPHEN_DOMAIN[process.env.NEXT_PUBLIC_SITE],
        customerToken,
        eligibleForVip: isEligibleForVip,
        id: basketToken,
        products: data,
      },
      headers: {
        id: basketToken,
        vipVersion: 'v1.1',
        ...httpCommonHeaders({ isGPK: true }),
      },
      method: 'PUT',
      withCredentials: true,
    });

    return basketToken;
  } catch (error) {
    console.error(error);
    ErrorTrackingService.logError(error);

    return null;
  }
};

/**
 * Backend service to deal with the merging of existing baskets to customer basket
 */
export const mergeBasket = async (basketToken, isEligibleForVip) => {
  const ct = ReactCookies.load(COOKIES.customerToken);
  if (!basketToken && !ct) {
    // we don't have any basket to fetch so no merge can be done
    return null;
  }

  const url = `${URLGETBASKET}?${setBasketParameters(
    basketToken,
    ct,
    true,
    isEligibleForVip,
  )}`;
  try {
    const { data } = await axios(url, {
      headers: {
        vipVersion: 'v1.1',
        ...httpCommonHeaders({ isGPK: true }),
      },
      method: 'GET',
      withCredentials: true,
    });

    if (data?.id && data.id.length > 10 && data.id !== basketToken) {
      ReactCookies.save(COOKIES.basketToken, data.id, {
        domain: `.${process.env.NEXT_PUBLIC_DOMAIN_NAME}`,
        maxAge: 60 * 60 * 24 * 7, // max age is 7 days
        path: '/',
        secure: true,
      });

      return data.id;
    }

    // return back original basket token if basket token does not exist
    return basketToken;
  } catch (error) {
    console.error(error);
    ErrorTrackingService.logError(error);

    return basketToken;
  }
};

export const getCustomerBasket = async (customerToken) => {
  if (!customerToken) {
    return null;
  }

  const customerHasBasketResponse = await fetchBasketWithURL(
    `${URLBASKET}?${setBasketParameters(null, customerToken, true)}`,
  );

  if (customerHasBasketResponse) {
    return customerHasBasketResponse;
  }

  return null;
};

export const linkCustomerToBasketToken = async (
  basketTokenResponse,
  isEligibleForVip,
) => {
  if (!basketTokenResponse) {
    return null;
  }
  const ct = ReactCookies.load(COOKIES.customerToken);
  const isVipFromLightbox = ReactCookies.load(COOKIES.lightboxVip) === 'true';
  const vipEligibility = isVipFromLightbox ? true : isEligibleForVip;

  const url = `${URLBASKET}/${basketTokenResponse.id}`;
  try {
    return await axios({
      data: {
        brand: BRAND_HYPHEN_DOMAIN[process.env.NEXT_PUBLIC_SITE],
        customerToken: ct,
        eligibleForVip: vipEligibility,
        id: basketTokenResponse.id,
        products: basketTokenResponse.products,
      },
      headers: {
        vipVersion: 'v1.1',
        ...httpCommonHeaders({ isGPK: true }),
      },
      method: 'PATCH',
      url,
      withCredentials: true,
    });
  } catch (error) {
    console.error(error);
    ErrorTrackingService.logError(error);

    return null;
  }
};

// Flow Documentation: https://wowcher.atlassian.net/wiki/spaces/WOWA/pages/3396042753/React+App+Basket+Merging+Flow+Front+end
export const validateAuthenticatedCustomerBasket = async (
  bt,
  isEligibleForVip,
) => {
  // check if the customer token is linked to a basket
  // if customer has no linked basket then link customer to local basket
  const ct = ReactCookies.load(COOKIES.customerToken);
  const customerBasketResponse = await getCustomerBasket(ct);
  if (customerBasketResponse) {
    // check if the local basket equal to customerBasketToken
    if (customerBasketResponse?.id === bt) {
      return bt;
    } else {
      return await mergeBasket(bt, isEligibleForVip);
    }
  } else {
    // making sure that basket token is not setting if undefined, also check for /checkout/undefined
    if (!bt || bt === 'undefined' || bt.length <= 10) {
      errorTrackingService.logError({
        details: {
          bt,
          ct,
        },
        error: 'bt is not defined',
        message: 'bt is not defined',
      });

      return '';
    }
    // fetch me the local basket details and link
    const basketTokenResponse = await fetchBasketWithURL(
      `${URLBASKET}/${bt}?${setBasketParameters(null, null, true)}`,
    );

    // this basket token is already been linked to another customer
    // if so create a new basket and copy the items and link the basket to customer
    // this is a situation where users are sharing baskets
    if (basketTokenResponse?.customerToken) {
      // duplicate the basket for this user
      const response = await createBasket(
        basketTokenResponse.products,
        isEligibleForVip,
        ct,
      );
      // don't return null for bt
      if (!response && !bt) {
        errorTrackingService.logError({
          error: 'No Basket Token',
          message: 'Basket token cannot be undefined.',
        });
      }

      return response || bt;
    } else {
      // adopt the orphaned basket
      await linkCustomerToBasketToken(basketTokenResponse, isEligibleForVip);

      if (
        basketTokenResponse &&
        basketTokenResponse?.id &&
        basketTokenResponse.id.length > 10
      ) {
        ReactCookies.save(COOKIES.basketToken, basketTokenResponse.id, {
          domain: `.${process.env.NEXT_PUBLIC_DOMAIN_NAME}`,
          maxAge: 60 * 60 * 24 * 7,
          path: '/',
          secure: true,
        });

        return basketTokenResponse.id;
      }

      errorTrackingService.logError({
        details: {
          basketTokenResponse,
          bt,
          ct,
        },
        error: 'return bt as default',
        message: 'return bt as default',
      });

      return bt;
    }
  }
};

/**
 * Generic helper function to remove a product from a basket
 *
 * @param {string} basketToken
 * @param {string} productId
 */
export const removeBasketProduct = async (basketToken, productId) => {
  if (!basketToken || !productId) {
    const error = {
      message: 'basketToken or productId are malformed',
      name: 'removeBasketProduct',
    };

    ErrorTrackingService.logError(error);
    throw new Error('args malformed');
  }
  const url = `${URLBASKET}/${basketToken}/product/${productId}`;
  await axios({
    headers: {
      vipVersion: 'v1.1',
      ...httpCommonHeaders({ isGPK: true }),
    },
    method: 'DELETE',
    url,
    withCredentials: true,
  });

  return true;
};

export const upadateVipStatusCall = async (status) => {
  if (!status) {
    const error = {
      message: 'Vip check undefined',
      name: 'upadateVipStatusCall',
    };
    ErrorTrackingService.logError(error);
    throw new Error('args malformed');
  }
  const url = `${URLVIPUPDATE}`;
  const data = {
    vipStatus: status,
  };
  try {
    return await axios({
      data,
      headers: httpCommonHeaders(),
      method: 'POST',
      url,
      withCredentials: true,
    });
  } catch (error) {
    return error;
  }
};

export const basketAddOns = async (basketToken, productId, selectedValue) => {
  const url = `${URLBASKET}/${basketToken}/addon`;

  const data = {
    addonType: 'VVE',
    productId,
    quantity: selectedValue,
  };
  try {
    const response = await axios({
      data,
      headers: httpCommonHeaders(),
      method: 'POST',
      url,
      withCredentials: true,
    });

    return response.data;
  } catch (error) {
    console.log('error', error);
  }
};
